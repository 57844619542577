export default {

    privacyText: 'Souhlasím se zpracováním osobních údajů (telefonní číslo) za účelem zasílání SMS od provozovatele této internetové stránky. Beru na vědomí, že bez poskytnutí osobních údajů v uvedeném rozsahu nemůže být požadovaná služba poskytována a že nedojde k jejich dalšímu zpracování ani poskytování bez mého předchozího souhlasu. Beru na vědomí, že osobní údaje budou zpracovávány po nezbytnou dobu, a to po dobu trvání mého zájmu o zasílání SMS. Tento udělený souhlas mohu kdykoli odvolat, v důsledku čehož dojde k ukončení poskytování služby ak výmazu poskytnutých osobních údajů. Podrobnější informace o ochraně osobních údajů naleznete v části Prohlášení o ochraně osobních údajů.',

    botClose: 'Ochrana proti botům zablokovala požadavek',
    captcha: 'Ochrana proti botům zablokovala požadavek',
    invalidCredentials: 'Neplatné přihlašovací údaje',
    smsSend: 'Nepodařilo se odeslat SMS s kódem k ověření',

    close: 'Zavřít',
    pay: 'Zaplatit',

    phoneNumberRequired: 'Musíte vyplnit telefonní číslo',
    motionTitleRequired: 'Musíte vyplnit titulek inzerátu',
    motionNameRequired: 'Musíte vyplnit jméno inzerenta',
    motionPriceRequired: 'Musíte vyplnit cenu za inzerát',
    motionDescriptionRequired: 'Musíte vyplnit popis inzerátu',
    motionPassRequired: 'Musíte vyplnit heslo pro přípravnou úpravu inzerátu',
    birthNumberRequired: 'Musíte vyplnit rodné číslo',

    publishingLoading: 'Načítám vlastnosti...',
    publishingNoAttributes: ' nemá žádné další vlastnosti',
    publishingChooseAttributes: '-- Vyberte vlastnost --',
    publishingCatNoAttributes: ' nemají žádné další vlastnosti',

    validatorRequired: 'Toto pole je povinné',
    validatorBirthNumber: 'Neplatné rodné číslo',
    validatorDate: 'Neplatný formát data, datum zadávejte ve tvaru "DD.MM.YYYY"',
    validatorEmail: 'Neplatná e-mailová adresa',
    validatorPhone: 'Neplatný formát telefonního čísla, telefonní číslo zadávejte ve tvaru "09XX XXX XXX" nebo "+421 9XX XXX XXX"',
    validatorZip: 'Neplatný formát PSČ',
	validatorFirstName: 'Neplatné křestní jméno',
	validatorSurname: 'Neplatné příjmení',
	validatorFullName: 'Neplatné jméno',
    validatorOk: 'V pořádku',
    validatorMinLength: 'Zadaný údaj nesmí být kratší než %min znaků',
    validatorMaxLength: 'Zadaný údaj nesmí být delší než %max znaků',
    validatorToken: 'Kód ověření není správný',

    documentSearchAria: 'Vyhledávání v dokumentech (seznam dokumentů se automaticky aktualizuje během psaní)',
    searchTitle: 'Vyhladávaní',

    fullcalendar_locale: 'cs',

    orderByCol: 'Seřadit podle stloupce'
}
