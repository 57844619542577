export default {

	privacyText: 'Súhlasím so spracovaním osobných údajov (telefónne čislo) za účelom zasielania SMS od prevádzkovateľa tejto internetovej stránky. Beriem na vedomie, že bez poskytnutia osobných údajov v uvedenom rozsahu nemôže byť požadovaná služba poskytovaná a že nedôjde k ich ďalšiemu spracúvaniu ani poskytovaniu bez môjho predchádzajúceho súhlasu. Beriem na vedomie, že osobné údaje budú spracúvané po nevyhnutnú dobu, a to po dobu trvania môjho záujmu o zasielanie SMS. Tento udelený súhlas môžem kedykoľvek odvolať, v dôsledku čoho dôjde k ukončeniu poskytovania služby a k výmazu poskytnutých osobných údajov. Podrobnejšie informácie o ochrane osobných údajov sa nachádzajú v časti Vyhlásenie o ochrane osobných údajov.',

	botClose: 'Ochrana proti botom zablokovala požiadavku',
	captcha: 'Ochrana proti botom zablokovala požiadavku',
    invalidCredentials: 'Neplatné prihlasovacie údaje',
    smsSend: 'Nepodarilo sa odoslať SMS s kódom na overenie',

	close: 'Zatvoriť',
	pay: 'Zaplatiť',

	phoneNumberRequired: 'Musíte vyplniť telefónne číslo',
    motionTitleRequired: 'Musíte vyplniť titulok inzerátu',
    motionNameRequired: 'Musíte vyplniť meno inzerenta',
    motionPriceRequired: 'Musíte vyplniť cenu za inzerát',
    motionDescriptionRequired: 'Musíte vyplniť popis inzerátu',
    motionPassRequired: 'Musíte vyplniť heslo pre prípradnú úpravu inzerátu',
	birthNumberRequired: 'Musíte vyplniť rodné číslo',

    motionTitleRequired2: 'Musíte vyplniť titulok podnetu',
    motionDescriptionRequired2: 'Musíte vyplniť popis podnetu',

	publishingLoading: 'Načítavam vlastnosti...',
	publishingNoAttributes: ' nemá žiadne ďalšie vlastnosti',
	publishingChooseAttributes: '-- Vyberte vlastnosť --',
	publishingCatNoAttributes: ' nemajú žiadne ďalšie vlastnosti',

	validatorRequired: 'Toto pole je povinné',
	validatorBirthNumber: 'Neplatné rodné číslo',
	validatorDate: 'Neplatný formát dátumu, dátum zadávajte v tvare "DD.MM.YYYY"',
	validatorEmail: 'Neplatná e-mailová adresa',
	validatorPhone: 'Neplatný formát telefónneho čísla, telefónne číslo zadávajte v tvare "09XX XXX XXX" alebo "+421 9XX XXX XXX"',
	validatorZip: 'Neplatný formát PSČ',
	validatorOk: 'V poriadku',
	validatorMinLength: 'Zadaný údaj nesmie byť kratší ako %min znakov',
	validatorMaxLength: 'Zadaný údaj nesmie byť dlhší ako %max znakov',
    validatorToken: 'Kód overenia nie je správny',

	documentSearchAria: 'Vyhľadávanie v dokumentoch (zoznam dokumentov sa automaticky aktualizuje počas písania)',
    searchTitle: 'Vyhľadávanie',

    fullcalendar_locale: 'sk'

}
